/** @jsx jsx */
import { jsx } from "theme-ui"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import PropTypes from "prop-types"

import Text from "./text"

const RichText = ({
  data,
  textStyle,
  listItemStyle,
  listStyle,
  listItemTextStyle,
  ...restProps
}) => {
  return (
    <div {...restProps}>
      {data &&
        data.json &&
        documentToReactComponents(data.json, {
          renderText: text => {
            return text.split("\n").reduce((children, textSegment, index) => {
              return [...children, index > 0 && <br key={index} />, textSegment]
            }, [])
          },
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => (
              <Text
                sx={{
                  color: "inherit",
                  mb: "20px",
                  ...textStyle,
                }}
              >
                {children}
              </Text>
            ),
            [BLOCKS.EMBEDDED_ASSET]: node => (
              <img
                sx={{}}
                src={node?.data?.target?.fields?.file["en-US"]?.url}
                alt=""
              />
            ),
            "asset-hyperlink": node => (
              <a
                href={node?.data?.target?.fields?.file["en-US"]?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {node.content[0].value}
              </a>
            ),
            [BLOCKS.UL_LIST]: (node, children) => (
              <ul
                sx={{
                  mb: 0,
                  "p+&": {
                    mt: -20,
                    ...listStyle,
                  },
                }}
              >
                {children}
              </ul>
            ),
            [BLOCKS.LIST_ITEM]: node => (
              <li
                sx={{
                  alignItems: "center",
                  display: "flex",
                  lineHeight: 1,
                  mb: 0,
                  position: "relative",
                  ":last-child": { mb: 0 },
                  "::before": {
                    content: '"\\2022"',
                    fontSize: 1,
                    position: "absolute",
                    top: "5px",
                    left: "-12px",
                  },
                  ...listItemStyle,
                }}
              >
                <Text sx={{ color: "inherit", ...listItemTextStyle }}>
                  {node?.content[0]?.content[0]?.value}
                </Text>
              </li>
            ),
          },
        })}
    </div>
  )
}

RichText.propTypes = {
  data: PropTypes.shape({
    json: PropTypes.shape({
      content: PropTypes.arrayOf(
        PropTypes.shape({
          nodeType: PropTypes.string.isRequired,
        })
      ),
    }),
  }),
  textStyle: PropTypes.object,
}

export default RichText
